import React from 'react';
import Image from 'gatsby-image';
import Layout from '../../components/Layout';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Favicon from '../../images/favicon.ico';
import CountUp from 'react-countup';
import FormDevis from '../../components/FormDevis';
import { Link } from 'gatsby';
const AVANTAGES = [
  'Assistance 24h/24, 7j/7 et 365 jours par an.',
  "Une équipe d'experts pour répondre à vos besoins",
  'Conseils personnalisés pour le meilleur choix pour vous.',
  'Devis instantané et Transparent',
  'Gamme de différentes serrures de qualité disponibles',
  'Prix Compétitifs',
];
function ChangementSerrure({ data }) {
  const { SuperSerrurierVan, TypeDeSerrure, SerrurierPicture } = data;
  return (
    <Layout>
      <Helmet>
        <link rel="icon" href={Favicon} />
        <meta charSet="utf-8" />
        <title>
          Changement de serrure à domicile | Service rapide et professionnel
        </title>
        <meta
          name="description"
          content="Changement de serrure résidentiel et commercial. Notre équipe qualifiée effectue des changements de serrure 24h/24. Obtenez un devis gratuit en un clic ou appelez-nous au +4179 130 47 68."
        />
      </Helmet>
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <main className="flex justify-between gap-4 lg:gap-8 mt-16 sm:mt-24 flex-col lg:flex-row">
          <div className="w-full lg:w-1/2">
            <h1 className="text-textcolor text-2xl tracking-tight font-extrabold sm:leading-none lg:text-4xl xl:text-5xl">
              Service de Changement de Serrure Professionnel et Rapide 24h/7
            </h1>
            <div className="w-full lg:hidden">
              <Image
                fluid={SuperSerrurierVan.childImageSharp.fluid}
                alt="Voiture de service utulisé pour les intervention de changement de serrures"
              />
            </div>
            <p className="mt-4 text-lg text-gray-600">
              Lorsque vous avez besoin de remplacer une serrure, faites
              confiance à notre équipe expérimentée pour un service de
              changement de serrure efficace et professionnel. Que ce soit pour
              une serrure endommagée, une mise à niveau de sécurité ou tout
              autre besoin de remplacement de serrure, notre équipe est prête à
              intervenir rapidement. Nous travaillons avec une variété de
              serrures, des modèles standard aux serrures haute sécurité, afin
              de garantir la sécurité de votre domicile ou de votre entreprise.
              Faites le choix de la tranquillité d'esprit en optant pour notre
              service de changement de serrure fiable.
            </p>
            <a
              id="call-button"
              href="tel:0791304768"
              className="mt-4 inline-flex items-center text-secondarycolor bg-gray-200 rounded-full p-2 sm:mt-5 sm:text-base lg:mt-6 lg:text-sm xl:text-base hover:text-secondarycolor-light"
            >
              <span className="px-3 py-2 text-white text-lg  lg:text-xl xl:text-2xl  leading-5  tracking-wide bg-primarycolor rounded-full">
                Appelez-nous
              </span>
              <span className="ml-3 text-lg  lg:text-xl xl:text-2xl font-semibold leading-5  ">
                079 130 47 68
              </span>
              <svg
                className="ml-2 w-6 h-6 "
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
          <div className="w-full hidden lg:block lg:w-1/2">
            <Image
              fluid={SuperSerrurierVan.childImageSharp.fluid}
              alt="Voiture de service utulisé pour les intervention de changement de serrures"
            />
          </div>
        </main>
        <div className="w-screen absolute left-0 mt-24 py-8 lg:py-14 h-fit bg-primarycolor">
          <dl className="text-center sm:max-w-7xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
            <div className="flex flex-col">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-200">
                Clients par an
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                <CountUp start={0} end={3000} duration={2.75} prefix="+ " />
              </dd>
            </div>{' '}
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-200">
                Temps d'intervention
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                <CountUp
                  end={20}
                  start={60}
                  duration={2.75}
                  suffix=" Minutes"
                />
              </dd>
            </div>
            <div className="flex flex-col mt-10 sm:mt-0">
              <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-200">
                De satisfaction
              </dt>
              <dd className="order-1 text-5xl font-extrabold text-white">
                <CountUp start={0} end={98} duration={2.75} suffix="%" />
              </dd>
            </div>
          </dl>
        </div>
        <div className="mt-128 lg:mt-96 rounded-md relative lg:grid lg:grid-cols-2 lg:gap-28 lg:items-center">
          <div className="relative py-12">
            <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
              <h2 className="inline text-primarycolor">
                Nos Engagements : Votre Serrurier de Confiance en Suisse Romande
              </h2>
            </div>
            <div className="mt-4 lg:mt-12">
              {AVANTAGES.map((e) => {
                return (
                  <div className="flex mt-4">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-8 w-8 text-green-700">
                        <svg
                          className=" h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M5 13l4 4L19 7"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div className="ml-4 text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-600">
                        {e}
                      </h3>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mt-8 sm:flex">
              <div className="block rounded-md shadow">
                <a
                  href="tel:0791304768"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light"
                >
                  Appelez-nous
                </a>
              </div>
            </div>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 hidden lg:hidden"
              width="784"
              height="404"
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="784"
                height="404"
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              ></rect>
            </svg>
            <div className="bg-white p-8 shadow-lg rounded-lg z-10">
              <FormDevis />
            </div>
          </div>
        </div>
        <div className="mt-12 lg:mt-32 rounded-md relative lg:grid lg:grid-cols-2 lg:gap-28 lg:items-center">
          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <Image
              fluid={TypeDeSerrure.childImageSharp.fluid}
              alt="Les types de serrures"
              className={'hidden lg:block'}
            />
          </div>{' '}
          <div className="relative py-12">
            <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
              <h2 className="inline text-primarycolor">
                Choisissez la Serrure Adaptée à Votre Niveau de Sécurité
              </h2>
              <Image
                fluid={TypeDeSerrure.childImageSharp.fluid}
                alt="Les types de serrures"
                className={'my-8 lg:hidden'}
              />
            </div>
            <ul className="mt-4 lg:mt-8 list-disc space-y-2 text-lg leading-6 font-medium text-gray-600">
              Pour assurer la sécurité de votre domicile ou de votre entreprise,
              le choix de la serrure appropriée est essentiel. Selon votre
              niveau de sécurité désiré, voici quelques options :
              <br />
              <li className="text-lg leading-6 font-medium text-gray-600">
                <b>Serrure à Crémone :</b> Une option de base adaptée aux portes
                intérieures et aux armoires, avec un seul point de verrouillage.
              </li>
              <li className="text-lg leading-6 font-medium text-gray-600">
                <b>Serrure 3 Points :</b> Pour une sécurité améliorée, cette
                serrure offre trois points de verrouillage et est souvent
                utilisée pour les portes d'entrée résidentielles.
              </li>
              <li className="text-lg leading-6 font-medium text-gray-600">
                <b>Serrure 5 Points :</b>Pour une sécurité maximale, choisissez
                cette serrure qui offre cinq points de verrouillage, idéale pour
                les portes d'entrée principales.
              </li>
              <br />
              En sélectionnant la serrure qui correspond à votre niveau de
              sécurité, vous protégerez efficacement votre espace. Un
              professionnel de la sécurité peut vous aider à faire le meilleur
              choix.
            </ul>

            <div className="mt-8 sm:flex">
              <div className="block rounded-md shadow">
                <a
                  href="tel:0791304768"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light"
                >
                  Appelez-nous
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 lg:mt-32 rounded-md relative lg:grid lg:grid-cols-2 lg:gap-28 lg:items-center">
          <div className="relative py-12">
            <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
              <h2 className="inline text-primarycolor">
                Tarifs et Prix pour un changement de Serrure
              </h2>
              <Image
                fluid={SerrurierPicture.childImageSharp.fluid}
                alt="un serrurier a geneve"
                className={'my-4 lg:hidden rounded-lg'}
              />
            </div>
            <div className="mt-4 lg:mt-8 list-disc space-y-4">
              <p className="text-lg leading-6 font-medium text-gray-600">
                En ce qui concerne la tarification, les frais associés à le
                changement de serrure par un serrurier agréé sont influencés par
                divers éléments, dont la durée de l'intervention, la distance à
                parcourir, Type de serrure, Marque et qualité de serrure,
                Complexité de l'installation, ainsi que Nombre de serrures à
                remplacer.
              </p>
            </div>

            <div className="mt-8 sm:flex">
              <div className="block rounded-md shadow">
                <a
                  href="tel:0791304768"
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light"
                >
                  Appelez-nous
                </a>
              </div>
            </div>
          </div>
          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <Image
              fluid={SerrurierPicture.childImageSharp.fluid}
              alt="Un serrurier a geneve"
              className={'hidden lg:block rounded-lg'}
            />
          </div>{' '}
        </div>
        <div className="mt-12 lg:mt-32">
          <h2 className="text-primarycolor text-2xl font-extrabold text-center tracking-tight sm:text-5xl">
            Nos Zones d’interventions
          </h2>
          <div className="pt-12 grid grid-cols-2 content-center gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-5">
            <div className="col-span-1 justify-self-center py-8 lg:px-8">
              <Link
                to="/valais"
                className="zone-area duration-150 text-gray-600 hover:text-primarycolor"
              >
                <Image
                  fixed={data.valais.childImageSharp.fixed}
                  className="h-32 max-w-32 mx-auto max-h-32 conton"
                  alt="Valais"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Valais
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-8">
              <Link
                to="/serrurier-geneve"
                className="zone-area duration-150 flex flex-col items-center text-gray-600 hover:text-primarycolor"
              >
                <Image
                  fixed={data.geneve.childImageSharp.fixed}
                  className="h-32 max-w-32 max-h-32 mx-auto conton"
                  alt="Genève"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Genève
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-8">
              <Link
                to="/neuchatel"
                className="zone-area duration-150 flex flex-col items-center text-gray-600 hover:text-primarycolor"
              >
                <Image
                  fixed={data.neuchatel.childImageSharp.fixed}
                  className="h-32 max-w-32  max-h-32 conton"
                  alt="Neuchatel"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Neuchatel
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-8 text-gray-600 hover:text-primarycolor">
              <Link
                to="/vaud"
                className="zone-area flex flex-col items-center duration-150"
              >
                <Image
                  fixed={data.vaud.childImageSharp.fixed}
                  className="h-32 max-w-32 mx-auto max-h-32 conton"
                  alt="Vaud"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Vaud
                </h3>
              </Link>
            </div>
            <div className="col-span-1 justify-self-center py-8 lg:px-14 text-gray-600 hover:text-primarycolor">
              <Link
                to="/fribourg"
                className="zone-area flex flex-col items-center duration-150"
              >
                <Image
                  fixed={data.fribourg.childImageSharp.fixed}
                  className="h-32 max-w-32 mx-auto max-h-32 conton"
                  alt="Fribourg"
                />
                <h3 className="text-2xl mt-2 text-center font-extrabold">
                  Fribourg
                </h3>
              </Link>
            </div>
          </div>
          <div className="mx-auto flex justify-center pb-12">
            <a
              id="call-button"
              href="tel:0791304768"
              className="mt-4 inline-flex items-center text-secondarycolor bg-gray-200 rounded-full p-2 sm:mt-5 sm:text-base lg:mt-6 lg:text-sm xl:text-base hover:text-secondarycolor-light"
            >
              <span className="px-3 py-2 text-white text-lg  lg:text-xl xl:text-2xl  leading-5  tracking-wide bg-primarycolor rounded-full">
                Appelez-nous
              </span>
              <span className="  ml-3 text-lg  lg:text-xl xl:text-2xl font-semibold leading-5  ">
                079 130 47 68
              </span>
              <svg
                className="ml-2 w-6 h-6 "
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ChangementSerrure;

export const query = graphql`
  query changement_serrure_page {
    SuperSerrurierVan: file(relativePath: { eq: "SuperSerrurierVan.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    SerrurierPicture: file(relativePath: { eq: "serrurier-geneve.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    TypeDeSerrure: file(relativePath: { eq: "type_de_serrure.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    fribourg: file(relativePath: { eq: "Fribourg.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    geneve: file(relativePath: { eq: "Geneve.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    neuchatel: file(relativePath: { eq: "neuchatel.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    valais: file(relativePath: { eq: "Valais.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
    vaud: file(relativePath: { eq: "Vaud.png" }) {
      childImageSharp {
        fixed(height: 100) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
  }
`;
